import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Divider, Collapse, ListSubheader, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';


const CollapsableListItem = (props) => {
    const { children, source, closeDrawer } = props;
    const [open, setOpen] = React.useState(false);
    const { push: redirectTo } = useHistory();
    const handleClick = () => {
        setOpen(!open);
    }

    return (
        <li>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <img src={props.icon} style={{ maxWidth: 32, maxHeight: 32 }} alt="Category Item" />
                </ListItemIcon>
                <ListItemText primary={children} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        source ?
                            source.map((subcat, i) => {
                                return (
                                    <ListItem key={i} button onClick={() => {
                                        closeDrawer();
                                        redirectTo(`/${subcat.name}`);
                                    }}>
                                        <ListItemText primary={subcat.name} />
                                    </ListItem>
                                )
                            })
                            :
                            null
                    }

                </List>
            </Collapse>
            <Divider />
        </li>
    )
}

const LeftDrawerItems = (props) => {
    const { categories, onClose, onBeWorkerClick, onHowWorksClick } = props;

    return (
        <div role="presentation">
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', padding: 22 }}>
                <Button variant="text" onClick={onHowWorksClick} color="default">How works?</Button>
                <Button variant="contained" onClick={onBeWorkerClick} color="secondary">Be a Worker</Button>
            </div>
            <Divider />
            <List>
                <ListSubheader>Categories</ListSubheader>
                {categories.map((cat, index) => (
                    <CollapsableListItem key={cat.id} closeDrawer={onClose} source={cat.subcategories} icon={cat.iconPath}>{cat.name}</CollapsableListItem>
                ))}
            </List>

        </div>
    )
}

const mapStateToProps = ({ categories }) => ({
    categories: categories.allCategories
});

export default connect(mapStateToProps)(LeftDrawerItems);