import React, { useEffect, useState } from 'react'; // Certifique-se de que 'useState' esteja importado
import { storeDemands, storeFilteredDemands, saveConfirmationCode, setRequestingCommonDemands, storeCommonDemandsPaged, 
    setRequestingOpenDemands, storeOpenDemandsPaged,
    setRequestingGoldenDemands, storeGoldenDemandsPaged, storeDemandById, setRequestingDemandById, setRequestingUnlockDemand } from "../../redux/modules/demands/demandsActions";
import { getRequest, postRequest, putRequest, handleErrors } from "../requests";
import { URL_BASE } from "../constants";
import store from "../../redux/globalStore";
import { SnackbarAPI } from "../../redux/modules/snackbars/snackbarApi";

export async function getAllDemands() {
    const demandsPayload = await getRequest(`${URL_BASE}/demands/available`);
    store.dispatch(storeDemands(demandsPayload.data));
    store.dispatch(storeFilteredDemands(demandsPayload.data));
    return demandsPayload;
}

export async function getFilteredDemands(state) {
    const demandsPayload = await getRequest(`${URL_BASE}/demands/available`);
    const filteredDemands = demandsPayload.data.filter((demand) => {
        return demand.stateCode.includes(state);
    });
    store.dispatch(storeFilteredDemands(filteredDemands));
    return filteredDemands;
}

export async function createDemandAndAccount(fullName, emailAddress, phoneNumber, category, subcategory, description,
    isFullTime, requirements, zipCode) {
    const demandAccount = {
        fullName,
        emailAddress,
        phoneNumber,
        category,
        subcategory,
        description,
        isFullTime,
        requirements,
        zipCode,
        price: 1,
        creationDate: new Date()
    }

    await postRequest(`${URL_BASE}/demands/web`, demandAccount, null, (error) => {
        const { status, data } = error;
        handleErrors(status, data);
    });
}

export async function askForConfirmationCode(phone, email) {
    const formData = new FormData();
    formData.append("phoneNumber", phone);
    formData.append("email", email);
    const payload = await postRequest(`${URL_BASE}/account/verification`, formData, null, (error) => {
        const { status, data } = error;
        handleErrors(status, data);
    });

    store.dispatch(saveConfirmationCode(payload.data));
    return payload.data;
}

export async function getCommonDemandsPaged(stateCode, city, category, subcategory, search, pageIndex) {
    const params = {
        stateCode,
        city,
        category,
        subcategory,
        search,
        pageIndex,
        pageSize: 6
    };
    store.dispatch(setRequestingCommonDemands(true));
    const token = store.getState().auth.loggedUser;
    //const token = undefined;
    const payload = await getRequest(`${URL_BASE}/demands/available/common/paged`, params, Boolean(token) ? { headers: { 'Authorization': `Bearer ${token}` } } : null)
    store.dispatch(storeCommonDemandsPaged(payload.data));
    store.dispatch(setRequestingCommonDemands(false));
    return payload.data;
}

export async function getOpenDemandsPaged(stateCode, city, category, subcategory, search, status, pageIndex) {
    const params = {
        stateCode,
        city,
        category,
        subcategory,
        search,
        status,
        pageIndex,
        pageSize: 6
    };
    store.dispatch(setRequestingOpenDemands(true));
    const token = store.getState().auth.loggedUser;
    //const token = undefined;
    const payload = await getRequest(`${URL_BASE}/demands/available/open/paged`, params, Boolean(token) ? { headers: { 'Authorization': `Bearer ${token}` } } : null)
    store.dispatch(storeOpenDemandsPaged(payload.data));
    store.dispatch(setRequestingOpenDemands(false));
    return payload.data;
}

export async function getGoldenDemandsPaged(stateCode, city, category, subcategory, search, pageIndex) {
    const params = {
        stateCode,
        city,
        category,
        subcategory,
        search,
        pageIndex,
        pageSize: 6
    };
    store.dispatch(setRequestingGoldenDemands(true));
    const token = store.getState().auth.loggedUser;
    const payload = await getRequest(`${URL_BASE}/demands/available/golden/paged`, params, Boolean(token) ? { headers: { 'Authorization': `Bearer ${token}` } } : null)
    store.dispatch(storeGoldenDemandsPaged(payload.data));
    store.dispatch(setRequestingGoldenDemands(false));
    return payload.data;
}

export async function getDemandById(idDemand) {
    store.dispatch(setRequestingDemandById(true));
    const token = store.getState().auth.loggedUser;
    const payload = await getRequest(`${URL_BASE}/demands/${idDemand}`, null, Boolean(token) ? { headers: { 'Authorization': `Bearer ${token}` } } : null);
    store.dispatch(storeDemandById(payload.data));
    store.dispatch(setRequestingDemandById(false));
    return payload.data;
}

export async function unlockDemand(idDemand, setShowLoginModal) {
    const showModal = false;
    store.dispatch(setRequestingUnlockDemand(true));
    const token = store.getState().auth.loggedUser;
    if (!Boolean(token)) {
        store.dispatch(setRequestingUnlockDemand(false));
        //SnackbarAPI.error("You need to be authenticated to perform this action");
        setShowLoginModal(true);
        getDemandById(idDemand);
        return;
    }
    await postRequest(`${URL_BASE}/demands/${idDemand}/unlock`, null, { headers: { 'Authorization': `Bearer ${token}` } }, (error) => {
        store.dispatch(setRequestingUnlockDemand(false));
        const { status, data } = error;
        handleErrors(status, data);
    });
    store.dispatch(setRequestingUnlockDemand(false));
    SnackbarAPI.success("Demand unlocked successfully! Use his information to call your customer");
    getDemandById(idDemand);
}

export async function changeStatus(idDemand, newStatus, setShowLoginModal) {  
    const token = store.getState().auth.loggedUser;
    if (!Boolean(token)) {
      //SnackbarAPI.error("You need to be authenticated to perform this action");
      setShowLoginModal(true);
      return;
    }
    
    try {
      const response = await putRequest(`${URL_BASE}/demands/${idDemand}/status?isActive=${Boolean(newStatus)}`, null, { headers: { 'Authorization': `Bearer ${token}` } });
      SnackbarAPI.success("Demand status changed successfully!");
      return response.data;
    } catch (error) {
      const { status, data } = error;
      handleErrors(status, data);
      throw error;
    }
  }