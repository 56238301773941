import React, { useState, useEffect, useReducer } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  CardActions,
  Modal,
  Typography,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getQuestionsBySubcategory } from "../../api/services/questionsServices";
import { saveDemandRequirements } from "../../redux/modules/demands/demandsActions";
import useWindowDimensions from "../0 - Shared/hooks/useWindowDimensions";

const AnswerQuestions = ({
  subcategory,
  showUserData,
  saveDemandRequirements,
}) => {
  const [questions, setQuestions] = useState([]);
  const [isFullTime, setIsFullTime] = useState(false);
  const initialQuestionReducer = {};
  const { width: windowWidth } = useWindowDimensions();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    getQuestionsBySubcategory(subcategory).then((result) => {
      setQuestions(result);
    });
    return () => {
      setQuestions([]);
      dispatch({ type: "CLEAN_OBJECT" });
    };
  }, [subcategory]);

  function internalQuestionReducer(state, action) {
    switch (action.type) {
      case "SET_VALUE":
        return { ...state, [action.property]: action.value };
      case "CLEAN_OBJECT":
        return initialQuestionReducer;
      default:
        throw new Error();
    }
  }

  function handleSearchProfessionals() {
    setShowConfirmation(true);
  }

  function handleConfirmationChange(event) {
    setConfirmed(event.target.checked);
  }

  function handleConfirmationSubmit() {
    if (confirmed) {
      const requirements = [];
      for (let prop in state) {
        if (state[prop].length > 0) {
          requirements.push(`${prop} ${state[prop]}`);
        }
      }
      saveDemandRequirements(requirements, isFullTime);
      showUserData();
    }
    setShowConfirmation(false);
  }

  const [state, dispatch] = useReducer(
    internalQuestionReducer,
    initialQuestionReducer
  );

  return (
    <Card
      style={{
        width: windowWidth > 960 ? "65%" : "100%",
        marginTop: windowWidth > 960 ? -100 : 15,
      }}
    >
      <CardHeader
        style={{ color: "#727272", textAlign: "center" }}
        title="Tell us what you need"
      />
      <CardContent>
        {questions.map((question) => {
          return (
            <TextField
              key={question.id}
              margin="normal"
              variant="outlined"
              className="question-input"
              name={question.query}
              label={question.query}
              fullWidth
              value={state[question.query]}
              onChange={({ target }) => {
                const { name, value } = target;
                dispatch({ type: "SET_VALUE", property: name, value });
              }}
            />
          );
        })}
        {/*<FormControlLabel
          value="is-full-time"
          control={
            <Checkbox
              checked={isFullTime}
              onChange={() => setIsFullTime(!isFullTime)}
            />
          }
          label="Is It Full Time?"
          labelPlacement="end"
        />*/}
      </CardContent>
      <CardActions>
        <Button
          onClick={handleSearchProfessionals}
          color="secondary"
          variant="contained"
        >
          Search
        </Button>
      </CardActions>
      <Modal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style={{ width: 300, padding: 16 }}>
          <CardHeader title="Confirm acceptance" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              By proceeding, you affirm that you have read and agree to our{" "}
              <a href="https://instajob.work/terms" target="_blank">
                terms of acceptance
              </a>
              .
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmed}
                  onChange={handleConfirmationChange}
                />
              }
              label="I confirm acceptance of the terms."
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={handleConfirmationSubmit}
              color="primary"
              variant="contained"
              disabled={!confirmed}
            >
              Confirm
            </Button>
            <Button onClick={() => setShowConfirmation(false)} color="default">
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveDemandRequirements }, dispatch);

export default connect(null, mapDispatchToProps)(AnswerQuestions);
